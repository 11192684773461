<template>
  <div></div>
</template>

<script>
import util from '../../js/util'
import { mapState } from 'vuex'
import orderApi from '../../api/order'

export default {
    name: 'modelReceipt',
    data(){
      return {
        orderId: '',
        order: null,
        loading: true
      }
    },
    computed: {
      ...mapState('client', ['token']),
       orderDate(){
            if(this.order == null) return ''
            var date = new Date(this.order.CreatedAt)
            return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
        },
        contactName(){
            if(this.order == null) return ''
            return this.order.client.name
        },
        expectedLocationName(){
            if(this.order == null) return ''
            return this.order.shipping.office
        },
        pointsEarned(){
            if(this.order == null) return '0'
            return Math.ceil(this.order.total/100)
        },
        goodList() {
          if(this.order == null) return []
          return this.order.items
        },
        orderTotal(){
            if(this.order == null) return '0.00'
            return (this.order.total/100).toFixed(2)
        }
    },
    mounted(){
        this.orderId = this.$route.params.orderId
        this.initOrder()
    },
    methods: {
      initOrder(){
        orderApi.getOrderById(this.token, this.orderId).then(resp=>{
            this.order = resp
            this.loading = false
        }).catch(err=>{
            console.log(err)
            this.loading = false
        })
      },
      formatDate(dateStr){
        var date = new Date(dateStr)
        return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
      },
      homeClick(){
        window.location.href = '/'
      }
    }
}
</script>

<style>

</style>